.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow: hidden auto;
}

.modal {
  z-index: 1050;
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  pointer-events: none;
  width: auto;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  align-items: center;
  min-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-centered:before {
  content: "";
  height: min-content;
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal {
  display: block !important;
}

@media (min-width: 992px) {
  .modal .modal-400 {
    max-width: 400px;
  }

  .modal .modal-600 {
    max-width: 600px;
  }

  .modal .modal-675 {
    max-width: 675px;
  }

  .modal .modal-900 {
    max-width: 900px;
  }

  .modal .modal-1100 {
    max-width: 1100px;
  }

  .modal .modal-960 {
    max-width: 960px;
  }

  .modal .modal-820 {
    max-width: 820px;
  }

  .modal .modal-fullscreen {
    width: 95%;
    max-width: none;
    height: 95%;
  }

  .modal .modal-halfscreen {
    width: 50vw;
    max-width: none;
  }
}

@media (max-width: 574px) {
  .modal {
    padding: 20px;
  }

  .modal-dialog {
    width: 90vw !important;
  }
}

.ReactModal__Overlay--after-open {
  background: rgba(0, 0, 0, .5);
  display: block;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  display: block;
}

.modal-full {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: .5rem 2rem;
}

.modal-content {
  height: 100%;
  border-style: none !important;
  border-radius: 24px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .14), 0 3px 4px rgba(0, 0, 0, .12), 0 1px 5px rgba(0, 0, 0, .2) !important;
}

.modal-content > [data-new-modal-leyout] {
  padding: 24px !important;
}

.modal-content.dark {
  color: #f8f9fa !important;
  background-color: #343a40 !important;
}
